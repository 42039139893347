<template>
  <div
    class="sm:hidden bg-white dark:bg-[#0C0E0F] shadow flex flex-row items-center justify-between gap-1"
  >
    <div class="w-full">
      <NuxtLink to="/" class="mobile-app-bar-button">
        <font-awesome-icon
          icon="fa-light fa-house"
          class="text-xl text-gray-400"
        />
      </NuxtLink>
    </div>

    <div class="w-full">
      <NuxtLink to="/notifications" class="relative mobile-app-bar-button">
        <span
          v-if="userStore.loggedIn && notificationCounts.unread"
          class="absolute top-5 right-5 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-primary-500 rounded-full"
        >
          {{ notificationCounts.unread }}
        </span>
        <font-awesome-icon
          icon="fa-light fa-bell"
          class="text-xl text-gray-400"
        />
      </NuxtLink>
    </div>

    <div class="w-full flex flex-col items-center justify-center">
      <Button
        :iconOnly="true"
        size="xl"
        class="flex mobile-app-bar-button"
        v-if="userStore.loggedIn"
        @click="showPostForm"
      >
        <font-awesome-icon icon="fa-light fa-plus" />
      </Button>
    </div>

    <div class="w-full">
      <NuxtLink to="/messages" class="relative mobile-app-bar-button">
        <span
          v-if="userStore.loggedIn && directMessageUnreadCount"
          class="absolute top-5 right-5 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-primary-500 rounded-full"
        >
          {{ directMessageUnreadCount }}
        </span>
        <font-awesome-icon
          icon="fa-light fa-envelope"
          class="text-xl text-gray-400"
        />
      </NuxtLink>
    </div>

    <div class="w-full">
      <div class="relative mobile-app-bar-button">
        <UPopover overlay :popper="{ placement: 'top-end' }" :ui="popperStyles">
          <div class="flex flex-col items-center justify-center">
            <UAvatar
              v-if="userStore.loggedIn"
              size="sm"
              class="mt-[9px]"
              :src="userStore.avatarUrl"
            />
            <font-awesome-icon
              v-else
              icon="fa-light fa-circle-user"
              class="text-xl text-gray-400"
            />
          </div>

          <template #panel="{ close }">
            <SiteExpandedNav :close="close" />
          </template>
        </UPopover>
      </div>
    </div>
  </div>

  <UModal v-model="createPostShowing" :ui="modalStyles" fullscreen>
    <Modal class="dark:border-b-2 dark:border-black">
      <PostForm @broadcast-feed-update="handleNewPost" />
    </Modal>
  </UModal>

  <UModal
    v-model="becomeACreatorShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <div class="flex flex-col gap-3 px-4 py-6">
      <h2 class="text-xl">
        Be the next and only
        <br class="hidden md:block lg:block" />
        <span class="text-primary-600">Favorite</span>
        for your fans
      </h2>

      <p class="mb-2">
        Start earning money by creating content for your fans. Get started by
        verifying your account.
      </p>

      <div class="text-center">
        <Button
          size="xl"
          class="text-xl"
          @click="navigateTo('/settings/verification')"
        >
          Get Started
        </Button>
      </div>
    </div>
  </UModal>
</template>

<script setup lang="ts">
  const userStore = useUserStore()
  const becomeACreatorShowing = ref(false)
  const createPostShowing = ref(false)

  const showPostForm = () => {
    if (userStore.approvedCreator) {
      createPostShowing.value = true
    } else {
      becomeACreatorShowing.value = true
    }
  }

  const modalStyles = {
    container: "items-start md:items-center !w-screen",
    background: "dark:bg-[#1b2022]",
    overlay: {
      background: "dark:bg-[#1b2022] dark:bg-opacity-50",
    },
    fullscreen: "w-screen h-auto",
  }

  const handleNewPost = () => {
    createPostShowing.value = false
  }
  const config = useRuntimeConfig()

  const {
    fetchCounts: fetchDirectMessageCounts,
    unreadCount: directMessageUnreadCount,
  } = useDirectMessageCounts()
  fetchDirectMessageCounts()

  const route = useRoute()
  const { $bus } = useNuxtApp()
  $bus.$on("new-direct-message", ({ payload }) => {
    fetchDirectMessageCounts()
  })

  const notificationCounts = ref({ total: 0, unread: 0, read: 0 })
  const loadNotificatonCounts = async () => {
    if (userStore.loggedIn) {
      try {
        const response = await $api(
          `${config.public.API_URL}/api/notifications/unread_count`,
        )
        notificationCounts.value = response
      } catch (err) {
        console.log("------> Error fetching notification counts", err)
      }
    }
  }
  loadNotificatonCounts()

  const popperStyles = {
    overlay: {
      base: "fixed inset-0 transition-opacity z-50",
      background: "bg-gray-200/25 dark:bg-gray-800/25",
      transition: {
        enterActiveClass: "ease-out duration-200",
        enterFromClass: "opacity-0",
        enterToClass: "opacity-100",
        leaveActiveClass: "ease-in duration-150",
        leaveFromClass: "opacity-100",
        leaveToClass: "opacity-0",
      },
    },
  }
</script>

<style scoped>
  .mobile-app-bar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    aspect-ratio: 1;

    &:active {
      background-color: rgba(128, 128, 128, 0.2);
    }
  }
</style>
